<!-- BlogView.vue -->
<template>
  <div>
    <HeaderSection />

    <!-- Blog Section -->
    <section class="relative py-16 bg-gradient-to-b from-gray-50 to-white">
      <!-- Header pattern -->
      
      
      <div class="container mx-auto px-4 relative">
        <!-- Heading -->
        <div class="bg-white rounded-lg shadow-md border border-gray-200 p-8 mb-12">
          <h2 class="text-4xl font-bold text-red-600 relative inline-block mb-4">
            Artikel Tentang Kami
            <span class="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 to-red-600"></span>
          </h2>
          <p class="text-gray-600 max-w-xl">
            Pelajari lebih lanjut tentang kami melalui artikel-artikel yang kami tulis untuk memberi Anda wawasan tentang visi, misi, dan layanan kami.
          </p>
        </div>

        <!-- Blog Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <router-link 
            v-for="post in blogPosts" 
            :key="post.id"
            :to="{ name: 'BlogDetail', params: { id: post.id }}"
            class="group bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
          >
            <!-- Image Container -->
            <div class="relative h-64 overflow-hidden">
              <div class="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
              <img 
                :src="post.image" 
                :alt="post.title"
                class="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              >
              <div class="absolute bottom-4 left-4 z-20">
                <span class="px-4 py-2 bg-red-500 text-white text-sm rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {{ post.category }}
                </span>
              </div>
            </div>

            <!-- Content -->
            <div class="p-6">
              <div class="flex items-center mb-4">
                <img 
                  :src="post.authorImage" 
                  alt="Author"
                  class="w-10 h-10 rounded-full mr-3"
                >
                <div>
                  <p class="text-sm font-medium text-gray-900">{{ post.author }}</p>
                  <p class="text-xs text-gray-500">{{ post.date }}</p>
                </div>
              </div>
              
              <h3 class="text-xl font-bold text-gray-900 mb-2 group-hover:text-red-600 transition-colors duration-300">
                {{ post.title }}
              </h3>
              <p class="text-gray-600 text-sm mb-4 line-clamp-2">{{ post.excerpt }}</p>
              
              <div class="flex items-center text-red-500">
                <span class="text-sm font-medium">Baca selengkapnya</span>
                <svg class="w-4 h-4 ml-2 transform group-hover:translate-x-2 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Pagination -->
        <div class="mt-12 flex justify-center">
          <nav class="flex items-center space-x-2">
            <button class="p-2 rounded-lg border border-gray-300 hover:bg-red-50 text-gray-500">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button class="px-4 py-2 rounded-lg border border-red-500 bg-red-500 text-white">1</button>
            <button class="px-4 py-2 rounded-lg border border-gray-300 hover:bg-red-50 text-gray-700">2</button>
            <button class="px-4 py-2 rounded-lg border border-gray-300 hover:bg-red-50 text-gray-700">3</button>
            <button class="p-2 rounded-lg border border-gray-300 hover:bg-red-50 text-gray-500">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </section>

    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'BlogView',
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      blogPosts: [
      {
        id: 1,
        title: 'Tips Memilih Pagar Garasi yang Cocok untuk Rumah Anda',
        excerpt: 'Memilih pagar garasi yang tepat adalah langkah penting untuk meningkatkan keamanan, kepraktisan, serta daya tarik visual rumah Anda. Selain berfungsi sebagai penghalang dan pelindung, pagar garasi juga berperan penting dalam mempercantik tampilan rumah.',
        image: '../blog/gambar1.png', // Sesuaikan dengan path image Anda
        category: 'Tips & Trick',
        author: 'Tim Triasa',
        authorImage: '../blog/gambar3.png',
        date: '18 Mar 2024',
      },
      {
        id: 2,
        title: 'Membuat Railing Tangga Rumah yang Aesthetic',
        excerpt: 'Railing tangga tidak hanya berfungsi sebagai penunjang keselamatan, tetapi juga sebagai elemen dekoratif yang dapat meningkatkan estetika rumah Anda. Memilih dan merancang railing tangga yang tepat dapat memberikan kesan yang stylish dan modern pada hunian.',
        image: '../blog/gambar2.png', // Sesuaikan dengan path image Anda
        category: 'Design',
        author: 'Tim Triasa',
        authorImage: '../blog/gambar3.png',
        date: '18 Mar 2024',
      },
        // Tambahkan lebih banyak post sesuai kebutuhan
      ]
    };
  },
};
</script>