<template>
    <div>
      <HeaderSection />
  
      <section class="relative py-16 bg-gradient-to-b from-gray-50 to-white">
       
        
        <div class="container mx-auto px-4 relative">
          <!-- Back button -->
          <router-link 
            to="/blog"
            class="inline-flex items-center text-red-500 mb-8 group hover:text-red-600 transition-colors duration-300"
          >
            <svg class="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            Kembali ke Artikel
          </router-link>
  
          <template v-if="post">
            <!-- Article Container -->
            <article class="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
              <!-- Hero Image -->
              <div class="relative h-[400px]">
                <img 
                  :src="post.image" 
                  :alt="post.title"
                  class="w-full h-full object-cover"
                >
                <div class="absolute inset-0 bg-gradient-to-t from-black/75 to-transparent"></div>
                <div class="absolute bottom-0 left-0 p-8 text-white">
                  <span class="px-4 py-2 bg-red-500 rounded-full text-sm mb-4 inline-block">
                    {{ post.category }}
                  </span>
                  <h1 class="text-4xl font-bold mb-4">{{ post.title }}</h1>
                  
                  <!-- Author info -->
                  <div class="flex items-center">
                    <img 
                      :src="post.authorImage" 
                      :alt="post.author"
                      class="w-12 h-12 rounded-full border-2 border-white mr-4"
                    >
                    <div>
                      <p class="font-medium">{{ post.author }}</p>
                      <p class="text-sm opacity-75">{{ post.date }} · {{ post.readTime }} read</p>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Content -->
              <div class="p-8">
                <div class="prose prose-lg max-w-none prose-headings:text-gray-900 prose-headings:font-bold prose-p:text-gray-600 prose-a:text-red-500 hover:prose-a:text-red-600">
                  <p class="lead text-xl text-gray-600 mb-8">{{ post.excerpt }}</p>
                  
                  <div v-html="post.content"></div>
                  
                  <!-- Tags -->
                  <div class="mt-12 flex flex-wrap gap-2">
                    <span 
                      v-for="tag in post.tags" 
                      :key="tag"
                      class="px-4 py-2 bg-gray-100 rounded-full text-sm text-gray-600 hover:bg-red-50 hover:text-red-600 transition-colors duration-300"
                    >
                      #{{ tag }}
                    </span>
                  </div>
                </div>
              </div>
            </article>
  
            <!-- Related Posts -->
            <div class="mt-16">
              <h3 class="text-2xl font-bold text-gray-900 mb-8">Artikel Terkait</h3>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <router-link 
                  v-for="related in relatedPosts" 
                  :key="related.id"
                  :to="{ name: 'BlogDetail', params: { id: related.id }}"
                  class="group bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
                >
                  <div class="relative h-48">
                    <img 
                      :src="related.image" 
                      :alt="related.title"
                      class="w-full h-full object-cover"
                    >
                    <div class="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </div>
                  <div class="p-6">
                    <h4 class="text-lg font-bold text-gray-900 mb-2 group-hover:text-red-600 transition-colors duration-300">
                      {{ related.title }}
                    </h4>
                    <p class="text-sm text-gray-600">{{ related.date }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </template>
  
          <!-- Loading State -->
          <div v-else class="max-w-4xl mx-auto py-12 text-center">
            <div class="animate-spin rounded-full h-12 w-12 border-4 border-red-500 border-t-transparent mx-auto"></div>
            <p class="mt-4 text-gray-600">Loading article...</p>
          </div>
        </div>
  
        <!-- Footer pattern -->
        <div class="absolute bottom-0 left-0 w-full">
          <div class="h-8 bg-gradient-to-b from-transparent to-gray-50"></div>
          <div class="h-6 bg-gradient-to-b from-gray-50 to-gray-100"></div>
        </div>
  
        <!-- Subtle texture overlay -->
        <div 
          class="absolute inset-0 opacity-5 pointer-events-none"
          :style="{
            backgroundImage: `url('data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23000000\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E')`,
            backgroundSize: '4px 4px'
          }"
        />
      </section>
  
      <FooterSection />
    </div>
  </template>
  
  <script>
  import HeaderSection from '@/components/HeaderSection.vue';
  import FooterSection from '@/components/FooterSection.vue';
  
  export default {
    name: 'BlogDetailView',
    components: {
      HeaderSection,
      FooterSection,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        post: null,
        relatedPosts: []
      };
    },
    created() {
      this.fetchPostData();
    },
    watch: {
      id: {
        handler: 'fetchPostData',
        immediate: true
      }
    },
    methods: {
      fetchPostData() {
        // Simulasi get data berdasarkan ID
        const articles = {
          '1': {
            id: 1,
            title: 'Tips Memilih Pagar Garasi yang Cocok untuk Rumah Anda',
            excerpt: 'Memilih pagar garasi yang tepat adalah langkah penting untuk meningkatkan keamanan, kepraktisan, serta daya tarik visual rumah Anda. Selain berfungsi sebagai penghalang dan pelindung, pagar garasi juga berperan penting dalam mempercantik tampilan rumah.',
            image: '../blog/gambar1.png',
            category: 'Tips & Trick',
            author: 'Tim Triasa',
            authorImage: '../blog/gambar3.png',
            date: '18 Mar 2024',
            readTime: '8 min',
            content: `
              <div class="prose prose-lg max-w-none">
                <p class="lead">Memilih pagar garasi yang tepat adalah langkah penting untuk meningkatkan keamanan, kepraktisan, serta daya tarik visual rumah Anda. Selain berfungsi sebagai penghalang dan pelindung, pagar garasi juga berperan penting dalam mempercantik tampilan rumah.</p>
                
                <h2>1. Pilih Desain yang Sesuai dengan Gaya Rumah</h2>
                <p>Desain pagar sebaiknya disesuaikan dengan gaya arsitektur rumah agar tampak serasi dan selaras. Untuk rumah bergaya minimalis, pilih desain pagar yang simpel dengan garis-garis tegas dan elemen modern. Misalnya, pagar dengan besi datar atau panel yang terlihat rapi dan elegan. Sementara itu, rumah bergaya klasik atau tradisional bisa memilih pagar dengan ornamen atau detail ukiran yang memberikan sentuhan artistik. Hal ini akan menambah kesan mewah dan estetika pada rumah.</p>
  
                <h2>2. Pilih Material yang Tahan Lama dan Berkualitas</h2>
                <p>Material pagar garasi adalah faktor utama dalam menentukan daya tahan dan perawatannya. Ada beberapa pilihan material populer seperti besi, aluminium, dan kayu, yang masing-masing memiliki kelebihan. Besi sangat kokoh dan memberikan perlindungan maksimal, namun perlu dilapisi anti-karat agar awet. Aluminium, di sisi lain, ringan dan tidak mudah berkarat, sehingga lebih praktis di daerah yang lembab. Kayu juga menjadi pilihan favorit karena menambah kesan alami dan hangat, tetapi membutuhkan perawatan lebih sering, seperti pelapisan anti-rayap dan pengecatan.</p>
  
                <h2>3. Utamakan Keamanan dengan Desain dan Sistem Penguncian yang Aman</h2>
                <p>Pagar garasi bukan hanya soal estetika; keamanan juga harus menjadi prioritas. Pilih desain yang tinggi dan sulit dipanjat untuk menghalangi akses yang tidak diinginkan. Pastikan pagar terbuat dari material yang kokoh dan tidak mudah rusak. Selain itu, perhatikan sistem penguncian pada pagar garasi. Sebaiknya pilih sistem kunci yang kuat dan tahan lama, seperti deadbolt atau kunci otomatis yang lebih sulit untuk dibobol.</p>
  
                <h2>4. Pertimbangkan Harga yang Sesuai dengan Anggaran</h2>
                <p>Pagar garasi hadir dalam berbagai rentang harga, tergantung material, desain, dan kompleksitas pemasangan. Tentukan anggaran Anda sebelum memilih pagar garasi, dan lakukan riset terhadap berbagai pilihan yang tersedia. Meski tergiur dengan harga yang murah, usahakan tetap memperhatikan kualitas agar Anda tidak perlu melakukan perbaikan atau penggantian terlalu cepat.</p>
  
                <h2>5. Perhatikan Kebutuhan Perawatan</h2>
                <p>Kebutuhan perawatan setiap jenis pagar bisa berbeda-beda. Pagar besi memerlukan perawatan pelapisan anti-karat secara berkala, terutama jika Anda tinggal di daerah yang lembap atau dekat pantai, untuk mencegah korosi. Pagar kayu memerlukan perawatan lebih, seperti pengecatan ulang dan pelapisan anti-rayap, agar tetap awet dan tidak mudah lapuk.</p>
  
                <h2>6. Pertimbangkan Aspek Privasi dan Visibilitas</h2>
                <p>Selain keamanan, pertimbangkan juga tingkat privasi yang Anda inginkan. Beberapa desain pagar memiliki celah yang lebar, memberikan visibilitas lebih ke dalam area garasi. Jika Anda menginginkan lebih banyak privasi, pilih pagar dengan desain yang lebih tertutup atau menggunakan panel solid.</p>
  
                <h2>Temukan Pagar Garasi Impian Anda!</h2>
                <p>Dengan mempertimbangkan tips-tips di atas, Anda dapat menemukan pagar garasi yang tidak hanya memberikan keamanan maksimal tetapi juga memperindah rumah Anda. Pastikan untuk memilih pagar yang sesuai dengan kebutuhan, gaya, dan anggaran Anda agar memberikan perlindungan dan nilai estetika yang optimal.</p>
              </div>
            `,
            tags: ['pagar', 'garasi', 'keamanan', 'desain']
          },
          '2': {
            id: 2,
            title: 'Membuat Railing Tangga Rumah yang Aesthetic',
            excerpt: 'Railing tangga tidak hanya berfungsi sebagai penunjang keselamatan, tetapi juga sebagai elemen dekoratif yang dapat meningkatkan estetika rumah Anda. Memilih dan merancang railing tangga yang tepat dapat memberikan kesan yang stylish dan modern pada hunian.',
            image: '../blog/gambar2.png',
            category: 'Design',
            author: 'Tim Triasa',
            authorImage: '../blog/gambar3.png',
            date: '18 Mar 2024',
            readTime: '6 min',
            content: `
              <div class="prose prose-lg max-w-none">
                <p class="lead">Railing tangga tidak hanya berfungsi sebagai penunjang keselamatan, tetapi juga sebagai elemen dekoratif yang dapat meningkatkan estetika rumah Anda. Memilih dan merancang railing tangga yang tepat dapat memberikan kesan yang stylish dan modern pada hunian.</p>
  
                <h2>1. Pilih Material yang Sesuai dengan Gaya Rumah</h2>
                <p>Material railing tangga sangat menentukan kesan keseluruhan. Untuk rumah bergaya industrial atau modern, railing berbahan logam seperti besi atau baja dengan tampilan minimalis sangat cocok. Untuk kesan alami dan hangat, railing berbahan kayu dengan warna natural dapat menjadi pilihan. Material kaca juga bisa dipertimbangkan untuk rumah bergaya modern minimalis, karena memberikan kesan bersih, terang, dan mewah.</p>
  
                <h2>2. Sesuaikan Desain dengan Konsep Interior</h2>
                <p>Desain railing tangga harus sesuai dengan konsep interior rumah agar terlihat serasi. Railing tangga minimalis dengan garis lurus dan tampilan sederhana cocok untuk rumah modern. Sebaliknya, untuk rumah klasik, pilih desain railing dengan detail dan ukiran artistik yang memberikan kesan elegan.</p>
  
                <h2>3. Perhatikan Kombinasi Warna</h2>
              <p>Pemilihan warna railing dapat memengaruhi tampilan ruang secara keseluruhan. Untuk tampilan yang harmonis, sesuaikan warna railing dengan warna elemen-elemen lain di rumah, seperti tangga, lantai, dan dinding. Anda juga bisa memilih warna kontras untuk memberi fokus visual pada tangga, misalnya railing hitam pada rumah berwarna terang atau railing kayu pada interior berwarna putih.</p>

              <h2>4. Tambahkan Aksen Dekoratif</h2>
              <p>Aksen tambahan seperti pegangan tangan berbentuk unik atau elemen tambahan di sepanjang railing dapat menambah daya tarik visual. Misalnya, pegangan tangan dari bahan kayu atau logam dengan bentuk melengkung memberikan kesan dinamis. Beberapa orang juga menambahkan dekorasi seperti tanaman hias kecil atau lampu pada railing untuk tampilan yang lebih hidup dan estetik.</p>

              <h2>5. Pilih Railing yang Mudah Dirawat</h2>
              <p>Pastikan Anda memilih railing yang sesuai dengan kebutuhan perawatan Anda. Railing berbahan logam biasanya membutuhkan perawatan anti-karat, sementara kaca membutuhkan pembersihan rutin agar tetap bersih dan bening. Kayu perlu dilapisi dengan pelindung untuk mencegah rayap dan kerusakan akibat perubahan suhu. Dengan memilih material yang mudah dirawat, railing tangga Anda akan tetap terlihat indah dan tahan lama.</p>

              <h2>6. Pertimbangkan Keamanan dan Kestabilan</h2>
              <p>Keamanan harus menjadi prioritas utama dalam desain railing tangga. Pastikan railing terpasang kuat dan stabil. Jika Anda memiliki anak-anak atau lansia di rumah, perhatikan jarak antara jeruji agar aman. Railing dengan jeruji vertikal bisa menjadi pilihan yang aman dan estetik.</p>

              <h2>Konsultasikan Desain Railing Tangga Anda dengan Ahli</h2>
              <p>Dengan tips di atas, Anda dapat memiliki railing tangga yang tidak hanya fungsional tetapi juga aesthetic, memberikan kesan elegan dan menyatu dengan konsep interior rumah. Hubungi kami untuk mendapatkan rekomendasi desain railing tangga berkualitas yang sesuai dengan gaya dan kebutuhan rumah Anda.</p>
            </div>
          `,
          tags: ['railing', 'tangga', 'desain', 'interior']
        }
      };

      // Set post data
      this.post = articles[this.id];
      
      // Set related posts
      this.relatedPosts = Object.values(articles)
        .filter(article => article.id !== parseInt(this.id))
        .map(article => ({
          id: article.id,
          title: article.title,
          image: article.image,
          date: article.date
        }));
    }
  }
};
</script>

<style scoped>
/* Custom styling for typography */
:deep(.prose) {
  max-width: none;
}

:deep(.prose h2) {
  color: #1a1a1a;
  margin-top: 2em;
  margin-bottom: 1em;
}

:deep(.prose p) {
  margin-bottom: 1.5em;
  line-height: 1.8;
}

:deep(.prose .lead) {
  font-size: 1.25em;
  color: #4a5568;
}

/* Smooth scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Image hover effects */
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

/* Transition effects */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Loading spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  :deep(.prose h2) {
    font-size: 1.5em;
  }
  
  :deep(.prose .lead) {
    font-size: 1.1em;
  }
}
</style>